<template>
  <div class="footer-container">
    <div class="top-box">
      <p>{{
          $t("L_BASIC.END_0001")
        }}</p>
    </div>
    <div class="bottom-box">
      <div class="bottom-main">
        <div class="top">
          <div class="top-content">
            <div>
              <router-link to="/index" tag="span">{{
                  $t("L_BASIC.FOOTER_0006")
                }}</router-link>
              <a href="http://www.vastitude.cn/" target="_blank">{{ $t("L_BASIC.FOOTER_0001") }}</a>
              <router-link to="/aboutUS/introduce" tag="span">{{
                  $t("L_BASIC.FOOTER_0002")
                }}</router-link>
              <router-link to="/aboutUS/contactUS" tag="span">{{
                  $t("L_BASIC.FOOTER_0003")
                }}</router-link>
            </div>
            <p>
              <span>{{  $t("L_BASIC.FOOTER_0007") }}</span>
              <span>{{  $t("L_BASIC.FOOTER_0008") }}</span>
            </p>
          </div>
          <div class="top-image">
            <span></span>
            <p>{{ $t("L_BASIC.FOOTER_0009") }}</p>
          </div>
        </div>
        <div class="bottom">
          <p>{{
              $t("L_BASIC.FOOTER_0004")
            }}</p>
          <p>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{
                $t("L_BASIC.FOOTER_0005")
              }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerModule"
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/footer";
</style>