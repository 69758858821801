<template>
  <div class="homeWrapper">
    <header-module ref="headerModule" :scrollTop="scrollTop"></header-module>
    <router-view class="routerClass" />
    <footer-module ref="footerModule"></footer-module>
    <return-top
      ref="returnTop"
      :scrollTop="scrollTop"
      :bottom="40"
      :right="40"
      :speed="50"
    ></return-top>
  </div>
</template>
<script>
import headerModule from "@/components/pc/header/header";
import footerModule from "@/components/pc/footer/footer";
import returnTop from "@/components/pc/returnTop";
export default {
  name: "home",
  components: {
    headerModule,
    footerModule,
    returnTop,
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //获取scroll偏移值
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped></style>
