<template>
  <div ref="btn" class="backtop">
    <div v-show="scrollTop > 0" @click="backtop">
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackTop",
  props: {
    scrollTop: {
      type: Number,
      default: 0,
    },
    bottom: {
      type: Number,
      default: 50,
    },
    right: {
      type: Number,
      default: 50,
    },
    speed: {
      type: Number,
      default: 100,
    },
  },
  mounted() {
    var btn = this.$refs.btn;
    (btn.style.right = this.right + "px"),
      (btn.style.bottom = this.bottom + "px");
  },
  data() {
    return {
      timer: null
    };
  },
  methods: {
    backtop() {
      this.timer = setInterval(() => {
        //获取滚动条到顶部的位置
        let osTop = this.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        // 判断到顶部的等式：滚动条到顶部的位置 + 当前窗口内容可视区 == 滚动条的总高度
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        if (osTop === 0) {
          clearInterval(this.timer);
        }
      }, 30);
    },
  },
  beforeDestroy(){
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.backtop {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 2023;
  div {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
  }
  span {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url("../../assets/image/pc/header/returntop.svg") 0 0 no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
}
</style>
