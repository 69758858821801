import { render, staticRenderFns } from "./returnTop.vue?vue&type=template&id=33d3a174&scoped=true&"
import script from "./returnTop.vue?vue&type=script&lang=js&"
export * from "./returnTop.vue?vue&type=script&lang=js&"
import style0 from "./returnTop.vue?vue&type=style&index=0&id=33d3a174&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d3a174",
  null
  
)

export default component.exports